import Grid from '@mui/material/Grid2';
import {Button, Card, CardActions, CardContent, Typography} from "@mui/material";

export const MainPage = () => {

    return (
        <Grid container spacing={2}>
            <Grid size={3}>
                <Card>
                    <CardContent>
                        <Typography variant="h5" component="div">
                            Words
                        </Typography>
                        <Typography variant="h6" component="div">
                            French
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button variant="contained" href="/learn/word/FRENCH">Learn</Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    );
}