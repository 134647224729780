import React, {useEffect, useState} from 'react';
import {Box, Typography, Button, TextField} from '@mui/material';
import axios from 'axios';
import {useParams} from 'react-router-dom';

const ExerciseDetails = () => {
    const [exercise, setExercise] = useState(null);
    const [editableExercise, setEditableExercise] = useState({});
    const endpoint = process.env.REACT_APP_API_URL;
    const {id} = useParams();

    useEffect(() => {
        const fetchExerciseDetails = async () => {
            try {
                const response = await axios.get(`${endpoint}/exercises/${id}`, {
                    withCredentials: true
                });
                setExercise(response.data);
                setEditableExercise(response.data);
            } catch (error) {
                console.error("Error fetching exercise details:", error);
            }
        };

        fetchExerciseDetails();
    }, [id, endpoint]);

    const handleSaveChanges = async () => {
        try {
            await axios.patch(`${endpoint}/admin/exercises/${id}`, editableExercise, {
                withCredentials: true
            });
            // Optionally, update the local state with the saved changes
            setExercise(editableExercise);
            alert('Changes saved successfully!');
        } catch (error) {
            console.error("Error saving changes:", error);
            alert('Failed to save changes.');
        }
    };

    const handleGenerateImage = async () => {
        try {
            await axios.post(`${endpoint}/admin/exercises/${id}/rules/GENERATE_IMAGE`, editableExercise, {withCredentials: true});
            // Optionally, update the local state with the saved changes
            setExercise(editableExercise);
            alert('Generate image request accepted!');
        } catch (error) {
            alert('Error!');
        }
    };

    const handleGenerateAudio = async () => {
        try {
            await axios.post(`${endpoint}/admin/exercises/${id}/rules/GENERATE_AUDIO`, editableExercise, {withCredentials: true});
            // Optionally, update the local state with the saved changes
            setExercise(editableExercise);
            alert('Generate audio request accepted!');
        } catch (error) {
            alert('Error!');
        }
    };

    if (!exercise) {
        return <p>Loading...</p>;
    }

    return (
        <Box sx={{textAlign: 'center'}}>

            <Box sx={{m: 2}}>
                <TextField
                    label="Primary content"
                    multiline
                    minRows={5}
                    fullWidth
                    value={editableExercise.contentPrimary || ''}
                    onChange={(e) => setEditableExercise({...editableExercise, contentPrimary: e.target.value})}
                />
            </Box>

            <Box sx={{m: 2}}>
                <TextField
                    label="Content spoiler"
                    multiline
                    minRows={5}
                    fullWidth
                    value={editableExercise.contentSpoiler || ''}
                    onChange={(e) => setEditableExercise({...editableExercise, contentPrimary: e.target.value})}
                />
            </Box>

            {exercise.audio && (
                <audio controls>
                    <source src={`data:audio/mpeg;base64,${exercise.audio}`} type="audio/mpeg"/>
                    Your browser does not support the audio element.
                </audio>
            )}

            <Box sx={{m: 2, textAlign: 'left'}}>
                <TextField
                    label="Tag 1"
                    value={editableExercise.tag1 || ''}
                    onChange={(e) => setEditableExercise({...editableExercise, tag1: e.target.value})}
                />

                <TextField
                    label="Tag 2"
                    value={editableExercise.tag2 || ''}
                    onChange={(e) => setEditableExercise({...editableExercise, tag2: e.target.value})}
                />

                <TextField
                    label="Tag 3"
                    value={editableExercise.tag3 || ''}
                    onChange={(e) => setEditableExercise({...editableExercise, tag3: e.target.value})}
                />
            </Box>

            <Typography variant="body2" color="text.secondary">Type: {exercise.type}</Typography>
            <Typography variant="body2" color="text.secondary">Language: {exercise.language}</Typography>

            {exercise.image && <Box component="img" src={`data:image/jpeg;base64,${exercise.image}`}
                                          alt={exercise.contentPrimary}/>}

            <Box sx={{m: 2, textAlign: 'left'}}>
                <Button variant="contained" color="primary" onClick={handleSaveChanges}> Save Changes </Button>
                <Button variant="contained" color="primary" onClick={handleGenerateImage}> Generate image </Button>
                <Button variant="contained" color="primary" onClick={handleGenerateAudio}> Generate audio </Button>
            </Box>

        </Box>
    );
};

export default ExerciseDetails;
