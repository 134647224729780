import React from 'react';
import {useNavigate} from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {useEffect, useState} from 'react';
import axios from 'axios';


const ExerciseList = () => {
    const endpoint = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const [exercises, setExercises] = useState([]);

    useEffect(() => {
        const fetchExercises = async () => {
            try {
                const response = await axios.get(`${endpoint}/admin/exercises`, {
                    withCredentials: true
                });
                setExercises(response.data);
            } catch (error) {
                console.error('Error fetching exercises:', error);
            }
        };
        fetchExercises();
    }, [endpoint]);

    const handleClick = (id) => {
        navigate(`/exercise/${id}`);
    };

    return (
        <List>
            {exercises.map((exercise) => (
                <ListItem button key={exercise.id} onClick={() => handleClick(exercise.id)}>
                    <ListItemText primary={exercise.contentPrimary} />
                    <ListItemText primary={exercise.type} secondary={exercise.language}/>
                </ListItem>
            ))}
        </List>
    );
};
export default ExerciseList;
