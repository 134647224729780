import './App.css';
import {Route, Routes} from "react-router-dom";
import { MainPage } from "./pages/main-page";
import ExerciseList from "./pages/exercise-list";
import ExerciseDetails from "./pages/exercise-details";
import ExerciseAdd from "./pages/exercise-add";
import {NavBar} from "./components/layout/navbar";
import WordTranslationExercise from "./components/exercise/word-translation-exercise";

function App() {

  return (
      <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          <NavBar/>
          <div style={{ flex: 1, padding: '16px' }}>
              <Routes>
                  <Route path="/" element={<MainPage />} />
                  <Route path="/add" element={<ExerciseAdd />} />
                  <Route path="/exercises" element={<ExerciseList />} />
                  <Route path="/exercise/:id" element={<ExerciseDetails />} />

                  <Route path="/learn/word/FRENCH" element={<WordTranslationExercise language={'FRENCH'} />} />
              </Routes>
          </div>

      </div>
  );
}

export default App;
