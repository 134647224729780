import {AppBar, Box, Button, Toolbar} from "@mui/material";

export const NavBar = () => {
    return (
        <Box>
            <AppBar position="static">
                <Toolbar>
                    <Button variant="contained" color="primary" href="/" >Main Page</Button>
                    <Button variant="contained" color="primary" href="/add">Add exercise</Button>
                    <Button variant="contained" color="primary" href="/exercises">Exercise list</Button>
                    <Button variant="contained" color="primary" href="https://api.quizengine.cc/hello">Login</Button>
               </Toolbar>
            </AppBar>
        </Box>
    );
};