import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Typography, Box, IconButton } from '@mui/material';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';

const WordTranslationExercise = ({ language }) => {
    const [exercise, setExercise] = useState(null);
    const [showComplexityButtons, setShowComplexityButtons] = useState(false);
    const [noExercisesLeft, setNoExercisesLeft] = useState(false);
    const [currentComplexity, setCurrentComplexity] = useState('');
    const endpoint = process.env.REACT_APP_API_URL; // Ensure you have REACT_APP_API_URL in your .env file

    const fetchExercise = async () => {
        try {
            const response = await axios.get(`${endpoint}/exercises/random/LEARN_WORD/${language}`, {
                withCredentials: true
            });

            if (response.status === 204) {
                setNoExercisesLeft(true);
                setExercise(null);
            } else {
                setExercise(response.data);
                setShowComplexityButtons(false);
                setNoExercisesLeft(false);
                setCurrentComplexity(response.data.complexity1); // Set the current complexity from API
            }
        } catch (error) {
            console.error('Error fetching exercise:', error);
        }
    };

    useEffect(() => {
        fetchExercise();
        // eslint-disable-next-line
    }, []);

    const handleSoundPlay = () => {
        if (exercise && exercise.audio) {
            const audio = new Audio("data:audio/mp3;base64," + exercise.audio);
            audio.play();
        }
    };

    const handleSubmit = () => {
        setShowComplexityButtons(true);
    };

    const handleComplexity = async (complexity) => {
        try {
            await axios.post(`${endpoint}/exercises/${exercise.id}/complexity1/${complexity}`, null, {
                withCredentials: true
            });
            setCurrentComplexity(complexity); // Update the current complexity after posting to API
            fetchExercise();
        } catch (error) {
            alert('Error updating exercise complexity');
            console.error(`Error updating exercise complexity to ${complexity}:`, error);
        }
    };

    return (
        <Box sx={{ p: 2, textAlign: 'center' }}>
            {noExercisesLeft ? (
                <Typography variant="h6">No exercises left, please come later</Typography>
            ) : exercise ? (
                <>
                    <Typography variant="h5">{exercise.contentPrimary}</Typography>
                    <IconButton onClick={handleSoundPlay}>
                        <VolumeUpIcon />
                    </IconButton>
                    <Box sx={{ mt: 2 }}>
                        <Button variant="contained" color="primary" onClick={() => { handleSoundPlay(); handleSubmit(); }}>
                            Submit
                        </Button>
                    </Box>
                    {showComplexityButtons && (
                        <Box sx={{ mt: 2 }}>
                            <Box>
                                <Typography variant="h5">{exercise.contentSpoiler}</Typography>
                            </Box>
                            <Button variant="contained" sx={{ backgroundColor: '#e0f7fa', color: '#006064', m: 1 }} onClick={() => handleComplexity('VERY_EASY')}>
                                Very Easy
                            </Button>
                            <Button variant="contained" sx={{ backgroundColor: '#c8e6c9', color: '#2e7d32', m: 1 }} onClick={() => handleComplexity('EASY')}>
                                Easy
                            </Button>
                            <Button variant="contained" sx={{ backgroundColor: '#fff9c4', color: '#f9a825', m: 1 }} onClick={() => handleComplexity('MEDIUM')}>
                                Medium
                            </Button>
                            <Button variant="contained" sx={{ backgroundColor: '#ffcdd2', color: '#c62828', m: 1 }} onClick={() => handleComplexity('HARD')}>
                                Hard
                            </Button>
                            <Typography
                                variant="body2"
                                sx={{
                                    mt: 2,
                                    color: 'gray'
                                }}
                            >
                                Current Complexity: {currentComplexity}
                            </Typography>
                        </Box>
                    )}
                </>
            ) : (
                <Typography variant="h6">Loading exercise...</Typography>
            )}
        </Box>
    );
};

export default WordTranslationExercise;