import React, {useState} from 'react';
import {
    Button,
    TextField,
    Select,
    MenuItem,
    Box
} from '@mui/material';
import axios from 'axios';

const ExerciseAdd = () => {
    const [editableExercise, setEditableExercise] = useState({type: 'LEARN_WORD', language: 'FRENCH'});
    const endpoint = process.env.REACT_APP_API_URL;

    const handleSaveChanges = async () => {
        try {
            await axios.post(`${endpoint}/admin/exercises`, editableExercise, {
                withCredentials: true
            });
            alert('Exercise saved successfully!');
        } catch (error) {
            alert('Failed to save changes.');
        }
    };

    return (
            <Box sx={{ textAlign: 'center' }}>

                <Box sx={{ m: 2 }}>
                    <TextField
                    label="Primary content"
                    multiline
                    minRows={5}
                    fullWidth
                    value={editableExercise.contentPrimary || ''}
                    onChange={(e) => setEditableExercise({...editableExercise, contentPrimary: e.target.value})}
                    />
                </Box>

                <Box sx={{ m: 2 }} >
                    <TextField
                        label="Spoiler content"
                        multiline
                        fullWidth
                        minRows={5}
                        value={editableExercise.contentSpoiler || ''}
                        onChange={(e) => setEditableExercise({...editableExercise, contentSpoiler: e.target.value})}
                    />
                </Box>

                <Box sx={{ m: 2,  textAlign: 'left' }}>
                    <Select
                        sx={{ m: 2 }}
                        variant={"standard"}
                        label="Exercise type"
                        value={editableExercise.type || ''}
                        onChange={(e) => setEditableExercise({...editableExercise, type: e.target.value})}
                    >
                        <MenuItem value="LEARN_WORD">Learn dictionary</MenuItem>
                        <MenuItem value="LEARN_PHRASE">Learn phrase</MenuItem>
                        <MenuItem value="LEARN_PRONUNCIATION">Learn pronunciation</MenuItem>
                        <MenuItem value="GRAMMAR">Grammar exercise</MenuItem>
                        <MenuItem value="GRAMMAR_RULE">Grammar rule</MenuItem>
                    </Select>

                    <Select
                        sx={{ m: 2 }}
                        variant={"standard"}
                        label="Language"
                        value={editableExercise.language || ''}
                        onChange={(e) => setEditableExercise({...editableExercise, language: e.target.value})}
                    >
                        <MenuItem value="FRENCH">French</MenuItem>
                        <MenuItem value="ENGLISH">English</MenuItem>
                    </Select>
                </Box>


                <Box sx={{ m: 2, textAlign: 'left' }}>
                    <TextField
                    label="Tag 1"
                    value={editableExercise.tag1 || ''}
                    onChange={(e) => setEditableExercise({...editableExercise, tag1: e.target.value})}
                    />

                    <TextField
                        label="Tag 2"
                        value={editableExercise.tag2 || ''}
                        onChange={(e) => setEditableExercise({...editableExercise, tag2: e.target.value})}
                    />

                    <TextField
                        label="Tag 3"
                        value={editableExercise.tag3 || ''}
                        onChange={(e) => setEditableExercise({...editableExercise, tag3: e.target.value})}
                    />
                </Box>

                <Box sx={{ m: 2,  textAlign: 'left' }}>
                    <Button variant="contained" color="primary" onClick={handleSaveChanges}> Save Changes </Button>
                </Box>

            </Box>
    );
};

export default ExerciseAdd;
